import { Outlet } from "react-router-dom";
import { Box, CssBaseline } from "@mui/material";
import MiniDrawer from "../sideBar/sideBar";
import OpenDrawerComponent from "../sideBar/openDrawer";
import { useSelector } from "react-redux";
import { sideBarStateSelector } from "../../../store/appReducer/app.selectors";
import { appLayoutStyles } from "./useStyles";

// Wrapper class for the APP
const AppLayout = () => {
  const open = useSelector(sideBarStateSelector);

  return (
    <Box sx={appLayoutStyles.boxContainer}>
      <CssBaseline />
      <MiniDrawer />
      {open && <OpenDrawerComponent />}
      <Box sx={appLayoutStyles.mainBoxContainer}>
        <Box component="main" sx={appLayoutStyles.scrollableContent}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};
export default AppLayout;
