import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../../../../utils/storeUtils";

const initialState = {
  acuity: "",
  acuityTotalByAdmissionType: [],
  acuityDetailedSsuSmoothing: {},
};

export const acuitySlice = createSlice({
  name: "overAllUtalization",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setAcuity(state, action) {
      state.acuity = action.payload;
    },
    setAcuityTotalByAdmissionType(state, action) {
      state.acuityTotalByAdmissionType = action.payload;
    },
    setAcuityDetailedSsuSmoothing(state, action) {
      state.acuityDetailedSsuSmoothing = action.payload;
    },
  },
});

export const {
  setAcuity,
  setAcuityTotalByAdmissionType,
  setAcuityDetailedSsuSmoothing,
} = acuitySlice.actions;

export const acuityReducer = acuitySlice.reducer;
