export const profileStyles = {
  profileBox: { flexGrow: 0 },
  profileAvatar: { width: "48px", height: "48px" },
  profileMenu: {
    "& .MuiList-root": { paddingBottom: 0 },
  },
  profileGrid: {
    padding: "10px",
    minWidth: "270px",
    maxWidth: "270px",
  },
  profileAvatar2: { width: "40px", height: "40px" },
  userGrid: {
    padding: "2px",
    paddingLeft: "12px",
    overflowWrap: "anywhere",
  },
  profileMenuItem: { height: "50px" },
  profileNameTypography: {
    color: "#262626",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "20px",
  },
  userNameTypography: { fontSize: "14px", color: "#707070" },
  profileGridLogo: {
    borderRadius: "50%",
    background: "#9f9999",
    color: "white",
    padding: "0.2rem",
    width: "3rem",
    height: "3rem",
    fontSize: "19px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  profileGridLogoMain: {
    borderRadius: "50%",
    width: "3.2rem",
    height: "3.2rem",
    background: "#9f9999",
    color: "white",
    padding: "0.2rem",
    fontSize: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
