export const appLayoutStyles = {
  logoText: {
    color: "white",
    fontSize: "45px",
    fontWeight: "800",
    marginLeft: "10px",
  },
  logoText2: {
    color: "white",
    fontSize: "10px",
    fontWeight: "100",
    paddingTop: (theme) => theme.spacing(1),
    marginLeft: (theme) => theme.spacing(0.2),
  },
  logo: {
    width: "155px",
  },
  subMenuItem: {
    pl: 4,
    backgroundColor: "inherit",
  },
  selectedSubMenuItem: {
    pl: 4,
    backgroundColor: "rgba(181, 203, 191, 0.5)",
  },
  mainBoxContainer: {
    borderRadius: "32px 0px 0px 0px",
    marginTop: "20px",
    backgroundColor: "white",
    flexGrow: 1,
    position: "relative",
    overflow: "hidden",
  },
  boxContainer: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    overflow: "hidden",
    backgroundColor: "#2C6150",
  },
  scrollableContent: {
    overflowY: "auto",
    height: "98vh",
  },
};
