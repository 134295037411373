const HomeIcon = ({ heightVal = "22" }) => {
  return (
    <svg
      width="20"
      height={heightVal}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 16.7099H14M9.0177 2.47396L2.23539 7.74909C1.78202 8.10171 1.55534 8.27802 1.39203 8.49882C1.24737 8.69441 1.1396 8.91474 1.07403 9.14901C1 9.41348 1 9.70066 1 10.275V17.5099C1 18.6301 1 19.1901 1.21799 19.6179C1.40973 19.9942 1.71569 20.3002 2.09202 20.492C2.51984 20.7099 3.07989 20.7099 4.2 20.7099H15.8C16.9201 20.7099 17.4802 20.7099 17.908 20.492C18.2843 20.3002 18.5903 19.9942 18.782 19.6179C19 19.1901 19 18.63 19 17.5099V10.275C19 9.70066 19 9.41348 18.926 9.14901C18.8604 8.91474 18.7526 8.69441 18.608 8.49882C18.4447 8.27802 18.218 8.10171 17.7646 7.74909L10.9823 2.47396C10.631 2.20071 10.4553 2.06408 10.2613 2.01156C10.0902 1.96522 9.9098 1.96522 9.73865 2.01156C9.54468 2.06408 9.36902 2.20071 9.0177 2.47396Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
