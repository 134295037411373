import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../../../../utils/storeUtils";

const initialState = {
  acuity: "",
};

export const overAllUrgencySlice = createSlice({
  name: "overAllUtalization",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),

  reducers: {
    setOverAllUrgency(state, action) {
      state.overAllUrgency = action.payload;
    },
  },
});

export const { setOverAllUrgency } = overAllUrgencySlice.actions;

export const overAllUrgencyReducer = overAllUrgencySlice.reducer;
