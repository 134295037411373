import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import { lazy } from "react";

import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";
import { enterpriseRoutes } from "./routes/index";
import NoMatch from "./pages/noMatch";

const SignIn = lazy(() => import("./pages/signIn"));
const SignUp = lazy(() => import("./pages/signUp"));
const Forgotpassword = lazy(() => import("./pages/forgotPassword"));
const OtpScreen = lazy(() => import("./pages/otpScreen"));
const Changepassword = lazy(() =>
  import("./pages/forgotPassword/ChangePassword")
);
const CheckYourEmail = lazy(() =>
  import("./pages/forgotPassword/CheckYourEmail")
);

const RoutesComponent = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PrivateRoute />}>
          {enterpriseRoutes?.map((route) => {
            return (
              <Route
                key={route.id}
                path={route.path}
                element={route.component}
              />
            );
          })}
          <Route path="*" element={<NoMatch />} />
        </Route>

        <Route
          path="/sign-in"
          element={<PublicRoute component={SignIn} restricted={true} />}
        />

        <Route
          path="sign-up"
          element={<PublicRoute component={SignUp} restricted={true} />}
        />
        <Route
          path="forgot-password"
          element={<PublicRoute component={Forgotpassword} restricted={true} />}
        />
        <Route
          path="check-your-email"
          element={<PublicRoute component={CheckYourEmail} restricted={true} />}
        />
        <Route
          path="change-password"
          element={<PublicRoute component={Changepassword} restricted={true} />}
        />
        <Route
          path="otp-verification"
          element={<PublicRoute component={OtpScreen} restricted={true} />}
        />
      </Routes>
    </Router>
  );
};

export default RoutesComponent;
