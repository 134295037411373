import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dateRange: {},
  currentSelectedDateRange: {},
  nonFormattedDateRange: {},
  dailyFilterDate: null,
};

export const selectedDateRangeSlice = createSlice({
  name: "selectedDateRange",
  initialState,
  reducers: {
    setSelectedDateRange(state, action) {
      state.dateRange = action.payload;
    },
    setCurrentSelecteddateRange(state, action) {
      state.currentSelectedDateRange = action.payload;
    },
    setNonFormattedDateRange(state, action) {
      state.nonFormattedDateRange = action.payload;
    },
    setDailyFilerDate(state, action) {
      state.dailyFilterDate = action.payload;
    },
  },
});

export const {
  setSelectedDateRange,
  setCurrentSelecteddateRange,
  setNonFormattedDateRange,
  setDailyFilerDate,
} = selectedDateRangeSlice.actions;
export const selectedDateRange = selectedDateRangeSlice.reducer;
