import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  sideBarMenuSelector,
  version,
} from "../../../store/appReducer/app.selectors";
import { setSideBarState } from "../../../store/appReducer/appReducer";
import { sidebarStyles } from "./useStyles";
import { useLocation, useNavigate } from "react-router-dom";
import { removeDateFromString } from "../../../utils/stringFunctions";

const OpenDrawer = () => {
  const menus = useSelector(sideBarMenuSelector);
  const versionNumber = useSelector(version);
  const filteredMenus = menus?.items?.filter((item) => item?.active);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMouse = (val) => {
    dispatch(setSideBarState(val));
  };

  const getLogoText = (sidebarStylesVal) => {
    return (
      <Grid container>
        <Grid item xs={12} style={{ maxHeight: "48px" }}>
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            flexDirection="row"
            wrap="nowrap"
          >
            <Grid item>
              <Typography sx={sidebarStylesVal.logoText}>sifio</Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography sx={sidebarStylesVal.logoText2}>
                    HEALTH
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item ml={1.5}>
          <Typography variant="overline">{versionNumber}</Typography>
        </Grid>
      </Grid>
    );
  };

  const handleClick = (val) => {
    if (val?.path) {
      navigate(val?.path);
    }
  };

  const getItemSx = (iVal) => {
    const trimmedLocationString = removeDateFromString(location.pathname);
    return iVal.includedPaths?.includes(trimmedLocationString)
      ? sidebarStyles.selectedDrawerItem
      : sidebarStyles.drawerItem;
  };

  return (
    <Grid
      container
      flexDirection="column"
      justifyContent="space-between"
      onMouseLeave={() => handleMouse(false)}
      sx={sidebarStyles.openDrawerGrid}
    >
      <Grid item sx={sidebarStyles.openDrawerScroll}>
        <Typography sx={sidebarStyles.openDrawerHeading}>Dashboard</Typography>
        {filteredMenus?.map((item) => {
          return (
            <Grid
              container
              key={item?.id}
              onClick={() => handleClick(item)}
              sx={getItemSx(item)}
            >
              <Grid item>{item.icon}</Grid>
              <Grid item>
                <Typography sx={sidebarStyles.openMenuItemLabel}>
                  {item.label}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid item>{getLogoText(sidebarStyles)}</Grid>
    </Grid>
  );
};

export default OpenDrawer;
