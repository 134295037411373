import Cookies from "js-cookie";
import { ApiService } from "../../services/api.services";
import { apiPromise } from "../../services/apiPromise";
import { setUser, setVersion } from "../../store/appReducer/appReducer";

const {
  callLogin,
  callForOtpVerification,
  callForResendOtpVerification,
  callForAppVersion,
} = ApiService;

export const callSignin = (payload, navigate) => (dispatch) => {
  let newData = new URLSearchParams();
  newData.append("username", payload.username);
  newData.append("password", payload.password);
  newData.append("grant_type", "password");
  newData.append("scope", "openid offline_access hospitalscope");
  newData.append("client_id", "reactSPA");
  newData.append("client_secret", "sifio_K9hJ8fT4");

  return apiPromise({
    dispatch,
    navigate,
    apiName: callLogin,
    payloadData: newData,
    followUpFunction: setUser,
    navPath: "otp-verification",
    optionalNavPath: "optimization",
    successSnackbar: true,
    isLogin: true,
  });
};

export const callOtpVerification = (payload, navigate) => (dispatch) => {
  let newData = new URLSearchParams();
  newData.append("email_otp", payload?.email_otp);
  newData.append("grant_type", "refresh_token");
  newData.append("scope", "openid offline_access hospitalscope");
  newData.append("refresh_token", payload?.refreshToken);
  newData.append("client_id", "reactSPA");
  newData.append("client_secret", "sifio_K9hJ8fT4");

  return apiPromise({
    dispatch,
    navigate,
    apiName: callForOtpVerification,
    payloadData: newData,
    navPath: "optimization",
    successSnackbar: true,
    isOtp: true,
  });
};

export const callResendOtpVerification = () => (dispatch) => {
  return apiPromise({
    dispatch,
    apiName: callForResendOtpVerification,
    successSnackbar: true,
  });
};

export const callGetAppVersion = () => (dispatch) => {
  return apiPromise({
    dispatch,
    apiName: callForAppVersion,
    followUpFunction: setVersion,
  });
};

export const callLogout = () => (dispatch) => {
  return new Promise((resolve) => {
    clearCache();
    dispatch({ type: "RESET_STORE" });
    resolve();
  });
};

const clearCache = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
  Cookies.remove("userData");

  window.location.reload();
};
