import { useSelector, useDispatch } from "react-redux";
import i18n from "i18next";

export const useAppDispatch = () => useDispatch();

export const useAppSelector = useSelector;

export const translate = (key) => {
  return i18n.t(key);
};

export const history = {
  navigate: null,
  location: null,
};
