import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function LogoutModal(props) {
  const { handleLogout, open, menu, handleClose } = props;

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          sx={{
            mt: "2rem",
            width: "400px",
          }}
        >
          <Typography
            sx={{
              textTransform: "none",
              fontSize: "27px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Are you sure you want to sign out ?
          </Typography>
        </BootstrapDialogTitle>
        <DialogActions
          sx={{
            mb: "1rem",
          }}
        >
          <Grid
            container
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            spacing={2.5}
          >
            <Grid item>
              <Button
                variant="outlined"
                sx={{ textTransform: "none", padding: "0.5rem", width: "6rem" }}
                onClick={(e) => handleClose(e)}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  textTransform: "none",
                  padding: "0.5rem",
                  width: "6rem",
                }}
                onClick={(e) => {
                  e?.stopPropagation();
                  handleLogout(menu);
                }}
              >
                Sign Out
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
