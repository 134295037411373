import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../../../../utils/storeUtils";

const initialState = {
  overallPercentageData: {},
  totalUtilizationData: {},
  utilBySurgeons: {},
  utilByProgram: {},
  underUtilByProgram: [],
  underUtilBySurgeons: {},
};

/**
 * Redux slice for managing utilizationVariables.
 *
 * This Redux slice defines the state structure and reducers for managing utilization data,
 * including overall percentage data, total utilization data, utilization by surgeons and programs,
 * and underutilization data. It also includes an action to revert all state to its initial values.
 */
export const appSlice = createSlice({
  name: "utilizationVariables",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setOverallPercentageData(state, action) {
      state.overallPercentageData = action.payload;
    },
    setTotalUtilizationData(state, action) {
      state.totalUtilizationData = action.payload;
    },
    setUtilBySurgeons(state, action) {
      state.utilBySurgeons = action.payload;
    },
    setUtilByProgram(state, action) {
      state.utilByProgram = action.payload;
    },
    setUnderUtilByProgram(state, action) {
      state.underUtilByProgram = action.payload;
    },
    setUnderUtilBySurgeons(state, action) {
      state.underUtilBySurgeons = action.payload;
    },
  },
});

export const {
  setOverallPercentageData,
  setTotalUtilizationData,
  setUtilBySurgeons,
  setUtilByProgram,
  setUnderUtilByProgram,
  setUnderUtilBySurgeons,
} = appSlice.actions;

export const utilizationReducer = appSlice.reducer;
