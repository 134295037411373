import Cookies from "js-cookie";
import { enqueueSnackbar } from "../components/molecules/notifier/notifier.actions";
import { translate } from "../store/hook";
import { ApiService } from "./api.services";
import { setUser } from "../store/appReducer/appReducer";

const MessagePrefix = "message.";

const getApiCall = (apiName, payloadData) => {
  return payloadData ? () => apiName(payloadData) : apiName;
};

export const apiPromise = async ({
  apiName,
  dispatch,
  payloadData,
  followUpFunction,
  navigate,
  navPath = "/optimization",
  successSnackbar = false,
  isLogin = false,
  isOtp = false,
  optionalNavPath,
}) => {
  try {
    const apiCall = getApiCall(apiName, payloadData);
    const res = await apiCall();
    const isSuccess = res?.data?.isSuccess;
    const responseData = res?.data?.data;
    const responseMessage = res?.data?.message;

    if (isSuccess) {
      getSuccessResponse({
        isLogin,
        responseData,
        isOtp,
        dispatch,
        followUpFunction,
        navigate,
        navPath,
        successSnackbar,
        responseMessage,
        optionalNavPath,
      });
    } else {
      if (res?.response?.status === 401) {
        return null;
      }
      showSnackBar(dispatch, "error", responseMessage);
    }

    return res?.data;
  } catch (err) {
    if (err?.response?.status !== 401) {
      showSnackBar(dispatch, "error", err);
    }
    throw err;
  }
};

const showSnackBar = (dispatch, type, mess) => {
  return dispatch(
    enqueueSnackbar({
      message: translate(MessagePrefix + mess) || mess,
      options: {
        variant: type,
      },
    })
  );
};

const getSuccessResponse = ({
  isLogin,
  responseData,
  isOtp,
  dispatch,
  followUpFunction,
  navigate,
  navPath,
  successSnackbar,
  responseMessage,
  optionalNavPath,
}) => {
  if (isLogin) {
    if (!responseData.twoFactorEnabled) {
      Cookies.set("accessToken", responseData?.accessToken);
      Cookies.set("refreshToken", responseData?.refreshToken);
      const userData = ApiService.decodeData(responseData?.accessToken);
      const tenants = JSON.parse(userData?.tenants);
      Cookies.set("tenantId", tenants[0]?.Id);
      Cookies.set("userData", JSON.stringify(userData));
      dispatch(setUser(userData));
      navigate(`/${optionalNavPath}`);
      dispatch(getPredictionMonteCarloSimulation());
    } else {
      Cookies.set("tempAccessToken", responseData?.accessToken);
      Cookies.set("tempRefreshToken", responseData?.refreshToken);
    }
  }
  if (isOtp) {
    Cookies.set("accessToken", responseData?.accessToken);
    Cookies.set("refreshToken", responseData?.refreshToken);
    Cookies.remove("tempAccessToken");
    Cookies.remove("tempRefreshToken");
    Cookies.remove("forgotPassEmail");
    const userData = ApiService.decodeData(responseData?.accessToken);
    Cookies.set("userData", JSON.stringify(userData));
    dispatch(setUser(userData));
  }
  if (followUpFunction) {
    dispatch(followUpFunction(responseData));
  }
  if (navigate) {
    !isLogin && navigate(`/${navPath}`);
  }
  if (successSnackbar) {
    showSnackBar(dispatch, "success", responseMessage);
  }
};

export const getPredictionMonteCarloSimulation = () => (dispatch) => {
  return apiPromise({
    dispatch,
    apiName: ApiService?.callPredictionMonteCarloSimulation,
    payloadData: {
      startDate: "2023-08-18T05:48:30.811Z",
      endDate: "2023-08-18T05:48:30.811Z",
      dateSelectionType: 0,
    },
  });
};
