export const sidebarStyles = {
  logoText: {
    color: "white",
    fontSize: "45px",
    fontWeight: "800",
    marginLeft: "10px",
  },
  logoText2: {
    color: "white",
    fontSize: "10px",
    fontWeight: "100",
    paddingTop: (theme) => theme.spacing(1),
    marginLeft: (theme) => theme.spacing(0.2),
  },
  logo: {
    width: "155px",
  },
  subMenuItem: {
    pl: 4,
    backgroundColor: "inherit",
  },
  selectedSubMenuItem: {
    pl: 4,
    backgroundColor: "rgba(181, 203, 191, 0.5)",
  },
  menuItem: {
    borderRadius: "6px",
    width: "48px",
    padding: "12px",
    marginBottom: (theme) => theme.spacing(1),
  },
  selectedMenuItem: {
    backgroundColor: "rgba(181, 203, 191, 0.5)",
    borderRadius: "6px",
    width: "48px",
    padding: "12px",
    marginBottom: (theme) => theme.spacing(1),
  },
  drawerItem: {
    backgroundColor: "inherit",
    width: "249px",
    height: "45px",
    "&:hover": { backgroundColor: "rgba(181, 203, 191, 0.2)" },
    borderRadius: "6px",
    padding: "10px 12px 8px 12px",
    marginBottom: "8px",
    "@media (max-height:600px)": {
      width: "249px",
    },
    "@media only screen and (min-height: 601px) and (max-height: 740px)": {
      width: "220px",
    },
    "@media only screen and (min-height: 741px)": {
      width: "209px",
    },
    "@media only screen and (min-height: 900px)": {
      width: "249px",
    },
  },
  selectedDrawerItem: {
    backgroundColor: "rgba(181, 203, 191, 0.5)",
    height: "45px",
    borderRadius: "6px",
    padding: "10px 12px 10px 12px",
    marginBottom: "8px",
    "@media (max-height:600px)": {
      width: "249px",
    },
    "@media only screen and (min-height: 601px) and (max-height: 740px)": {
      width: "220px",
    },
    "@media only screen and (min-height: 741px)": {
      width: "209px",
    },
    "@media only screen and (min-height: 900px)": {
      width: "249px",
    },
  },
  drawerHeader: {
    maxHeight: "50px",
    color: "white",
    fill: "white",
    marginTop: "20px",
    marginBottom: (theme) => theme.spacing(2),
  },
  fullHeight: { height: "100%" },
  listItemIcon: { color: "white", marginLeft: "1px" },
  openDrawerGrid: {
    height: "100vh",
    color: "white",
    cursor: "pointer",
    overflow: "hidden",
    "@media (max-height:600px)": {
      maxWidth: "18%",
      padding: "10px",
      paddingTop: "30px",
    },
    "@media only screen and (min-height: 601px) and (max-height: 740px)": {
      maxWidth: "18%",
      padding: "10px",
      paddingTop: "30px",
    },
    "@media only screen and (min-height: 741px)": {
      maxWidth: "16%",
      padding: "10px",
      paddingTop: "30px",
    },
    "@media only screen and (min-height: 900px)": {
      maxWidth: "14%",
      padding: "10px",
      paddingTop: "30px",
    },
    transition: (theme) =>
      theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
  },
  openDrawerHeading: { marginBottom: "10px" },
  openMenuItemLabel: {
    marginLeft: "10px",
    fontSize: "16px",
    fontFamily: "Aeonik, Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: "400",

    "@media (max-height:600px)": {
      fontSize: "16px",
    },
    "@media only screen and (min-height: 601px) and (max-height: 740px)": {
      fontSize: "15px",
    },
    "@media only screen and (min-height: 741px)": {
      fontSize: "16px",
    },
    "@media only screen and (min-height: 900px)": {
      fontSize: "18px",
    },
  },
  scrollableContent: { overflowY: "auto", height: "100vh" },
  openDrawerScroll: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "90%",
  },
  drawerMainContainer: { "& .MuiPaper-root": { borderRadius: 0 } },
};
