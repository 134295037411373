import { styled } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";

const closedMixin = (theme, openVal) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: openVal ? "#1E4237" : "#2C6150",

  color: "white",
  border: "none",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
});

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,

  whiteSpace: "nowrap",
  boxSizing: "border-box",
  border: "none",

  ...closedMixin(theme),
  "& .MuiDrawer-paper": closedMixin(theme, open),
}));
