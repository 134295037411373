import { createSlice } from "@reduxjs/toolkit";
import { revertAll } from "../../../../../utils/storeUtils";

const initialState = {
  caseVolume: "",
  caseVolumeTwentyWeeksSummary: {},
};

export const caseVolumeSlice = createSlice({
  name: "overAllUtalization",
  initialState,
  extraReducers: (builder) => builder.addCase(revertAll, () => initialState),
  reducers: {
    setCaseVolume(state, action) {
      state.caseVolume = action.payload;
    },
    setCaseVolumeTwentyWeeksSummary(state, action) {
      state.caseVolumeTwentyWeeksSummary = action.payload;
    },
  },
});

export const { setCaseVolume, setCaseVolumeTwentyWeeksSummary } =
  caseVolumeSlice.actions;

export const caseVolumeReducer = caseVolumeSlice.reducer;
