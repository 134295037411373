// Import required modules
import Cookies from "js-cookie";
import { HttpClient } from "./http.services";
import { BASE_URL } from "../config";
import jwt_decode from "jwt-decode";
import { setUser } from "../store/appReducer/appReducer";

// Define various types of API request methods
const ApiRequestTypes = {
  get: "get",
  del: "del",
  put: "put",
  postWithXmlToken: "postWithXmlToken",
  postWithXmlNoToken: "postWithXmlNoToken",
  postWithJsonToken: "postWithJsonToken",
  postWithJsonNoToken: "postWithJsonNoToken",
  getWithJsonToken: "getWithJsonToken",
};

/**
 * Retrieve application variables (styles) associated with a specific facility.
 *
 *
 * @param {string} FacilityId - The unique identifier of the facility for which styles are requested.
 * @returns {Promise<any>} A Promise that resolves with the fetched application variables data.
 */
const getAppVariables = (FacilityId) => {
  const url = `Account/GetStylesByFacility`;
  return HttpClient[ApiRequestTypes.get](url, { FacilityId });
};

/**
 * Function to create form data for refreshing access tokens
 *
 * @returns {FormData} A FormData object with the required parameters for token refresh.
 */
const createRefreshTokenFormData = () => {
  const formdata = new FormData();
  formdata.append("grant_type", "refresh_token");
  formdata.append("scope", "openid offline_access securityassessmentscope");
  formdata.append("client_id", "reactSPA");
  formdata.append("client_secret", "reactSPAsecret");
  formdata.append("refresh_token", Cookies.get("remotely_refresh_token"));
  return formdata;
};

/**
 * Decode a JSON Web Token (JWT) data string and return its payload.
 *
 * @param {string} data - The JWT data string to decode.
 * @returns {object|null} The decoded JWT payload as an object, or null if decoding fails.
 */
export const decodeData = (data) => {
  return jwt_decode(data);
};

/**
 * Function to get user data from the access token.
 *
 * @returns {object|null} The user data decoded from the access token as an object, or null if decoding or access token retrieval fails.
 */
const getUserData = () => {
  return decodeData(getAccessToken());
};

/**
 * Function retrieves the user's access token stored in a cookie
 * named "accessToken" and returns it as a string.
 *
 * @returns {string|null} The user's access token as a string, or null if it's not found.
 */
const getAccessToken = () => {
  return Cookies.get("accessToken");
};

/**
 * Function to authenticate the app and set user data in the redux store
 *
 *
 * @param {function} dispatch - The Redux dispatch function.
 * @returns {Promise<void>} A Promise that resolves when the authentication is complete.
 */
export const authenticateApp = () => (dispatch) => {
  return new Promise((resolve) => {
    const userData = getUserData();
    dispatch(setUser(userData));
    return resolve();
  });
};

// Define various API call functions using different request types

// Function to call the login API
const callLogin = (data) => {
  const url = `${BASE_URL}Authentication/Auth`;
  return HttpClient[ApiRequestTypes.postWithXmlNoToken](url, data);
};

// Function to call the OTP verification API
const callForOtpVerification = (data) => {
  const url = `${BASE_URL}Authentication/VerifyTwoFAEmailOtp`;
  return HttpClient[ApiRequestTypes.postWithXmlToken](url, data);
};
//Function to call resend otp
const callForResendOtpVerification = (data) => {
  const token = Cookies.get("tempAccessToken");
  const url = `${BASE_URL}Authentication/ResendTwoFAEmailOtp`;
  return HttpClient[ApiRequestTypes.get](url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

//Function to call forgot Password token Request
const callForgotPassword = (data) => {
  const url = `${BASE_URL}Account/ForgotPasswordTokenRequest`;
  return HttpClient[ApiRequestTypes.postWithJsonNoToken](url, data);
};

//Function to call for change password
const callForChangePassword = (data) => {
  const url = `${BASE_URL}Account/ChangePassword`;
  return HttpClient[ApiRequestTypes.postWithJsonNoToken](url, data);
};

//Function to call for version
const callForAppVersion = () => {
  const url = `${BASE_URL}Account/GetVersion`;
  return HttpClient[ApiRequestTypes.getWithJsonToken](url);
};

//Function to call Overal Utilization Dashboard Summary
const callOveralUtilizationDashboardSummary = (data) => {
  const url = `${BASE_URL}UtilizationData/OveralUtilizationDashboardSummary`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Case Volume Selected Period Dashboard Summary
const callCaseVolumeSelectedPeriodDashboardSummary = (data) => {
  const url = `${BASE_URL}CaseVolume/CaseVolumeSelectedPeriodDashboardSummary`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Acuity Dashboard Summary
const callAcuityDashboardSummary = (data) => {
  const url = `${BASE_URL}AcuityData/AcuityDashboardSummary`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Urgency Dashboard Summary
const callUrgencyDashboardSummary = (data) => {
  const url = `${BASE_URL}UrgencyData/UrgencyDashboardSummary`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Urgency Detailed Cases By Time Type
const callUrgencyDashboardCasesByTimeType = (data) => {
  const url = `${BASE_URL}UrgencyData/UrgencyDetailedCasesByTimeType`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Over All Utilization Percentage
const callOverAllUtilizationPercentage = (data) => {
  const url = `${BASE_URL}UtilizationData/OverAllUtilizationPercentage`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Total Utilization By All Program
const callTotalUtilizationByAllProgram = (data) => {
  const url = `${BASE_URL}UtilizationData/TotalUtilizationByAllProgram`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Under Utilization By Program
const callUnderUtilizationByProgram = (data) => {
  const url = `${BASE_URL}UtilizationData/UnderUtilizationByProgram`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Utilization By Program
const callUtilizationByProgram = (data) => {
  const url = `${BASE_URL}UtilizationData/UtilizationByProgram`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Under Utilization By Surgeon
const callUnderUtilizationBySurgeons = (data) => {
  const url = `${BASE_URL}UtilizationData/UnderUtilizationBySurgeon`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Utilization By Surgeon
const callUtilizationBySurgeons = (data) => {
  const url = `${BASE_URL}UtilizationData/UtilizationBySurgeon`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Get Acuity Detailed Total Cases ByAdmissionType
const callAcuityByAdmissionType = (data) => {
  const url = `${BASE_URL}AcuityData/GetAcuityDetailedTotalCasesByAdmissionType`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Get Acuity Detailed SsuSmoothing
const callAcuityDetailedSsuSmoothing = (data) => {
  const url = `${BASE_URL}AcuityData/GetAcuityDetailedSsuSmoothing`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Case Volume Previous Twenty Weeks Dashboard Summary
const callCaseVolumeTwentyWeeksSummary = (data) => {
  const url = `${BASE_URL}CaseVolume/CaseVolumePreviousTwentyWeeksDashboardSummary`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Optimization Recommentation ByWeek
const callOptimizationRecommentationByWeek = (data) => {
  const url = `${BASE_URL}OptimizationRecommentation/OptimizationRecommentationByWeek`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Optimization Recommentation Overview By Week
const callOptimizationRecommentationOverviewByWeek = (data) => {
  const url = `${BASE_URL}OptimizationRecommentation/OptimizationRecommentationOverviewByWeek`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Get Optimization Surgical Program Daily Overview
const callOptimizationSurgicalProgramDailyOverview = (data) => {
  const url = `${BASE_URL}OptimizationRecommentation/GetOptimizationSurgicalProgramDailyOverview`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Optimization Recommentation Utilization And Case
const callOptimizationRecommentationUtilizationAndCase = (data) => {
  const url = `${BASE_URL}OptimizationRecommentation/OptimizationRecommentationUtilizationAndCase`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Optimization Project Out Come By Or
const callOptimizationProjectOutComeByOr = (data) => {
  const url = `${BASE_URL}OptimizationRecommentation/OptimizationProjectOutComeByOr`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Optimization Project Unutilised Time By Or
const callOptimizationProjectUnutilisedTimeByOr = (data) => {
  const url = `${BASE_URL}OptimizationRecommentation/OptimizationProjectUnutilisedTimeByOr`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Get Optimization Program Anticipated End Time Deviation
const callOptimizationProgramAnticipatedEndTimeDeviation = (data) => {
  const url = `${BASE_URL}/OptimizationRecommentation/GetOptimizationProgramAnticipatedEndTimeDeviation`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Get Dashboard ThreeWeeks out Planned
const callDashboardThreeWeeksoutPlanned = (data) => {
  const url = `${BASE_URL}BiWeeklyBlockUtilization/GetDashboardThreeWeeksoutPlanned`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Daily Insights Recommendations
const callDailyInsightsRecommendations = (data) => {
  const url = `${BASE_URL}RecommentationWarning/DailyInsightsRecommendations`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Daily Insights Warnings
const callDailyInsightsWarnings = (data) => {
  const url = `${BASE_URL}RecommentationWarning/DailyInsightsWarnings`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

//Function to call Prediction MonteCarlo Simulation
const callPredictionMonteCarloSimulation = (data) => {
  const url = `${BASE_URL}Prediction/PredictionMonteCarloSimulation`;
  return HttpClient[ApiRequestTypes.postWithJsonToken](url, data);
};

// Export all API call functions as a single object
export const ApiService = {
  getAppVariables,
  callLogin,
  createRefreshTokenFormData,
  callForgotPassword,
  callForChangePassword,
  callForOtpVerification,
  callForResendOtpVerification,
  callAcuityDashboardSummary,
  callCaseVolumeSelectedPeriodDashboardSummary,
  callUrgencyDashboardSummary,
  callUrgencyDashboardCasesByTimeType,
  callOveralUtilizationDashboardSummary,
  callOverAllUtilizationPercentage,
  callTotalUtilizationByAllProgram,
  callUtilizationBySurgeons,
  callUnderUtilizationBySurgeons,
  callUtilizationByProgram,
  callUnderUtilizationByProgram,
  decodeData,
  callAcuityByAdmissionType,
  callAcuityDetailedSsuSmoothing,
  callCaseVolumeTwentyWeeksSummary,
  callOptimizationRecommentationByWeek,
  callOptimizationRecommentationOverviewByWeek,
  callOptimizationRecommentationUtilizationAndCase,
  callOptimizationProjectOutComeByOr,
  callOptimizationProjectUnutilisedTimeByOr,
  callDashboardThreeWeeksoutPlanned,
  callOptimizationProgramAnticipatedEndTimeDeviation,
  callOptimizationSurgicalProgramDailyOverview,
  callDailyInsightsRecommendations,
  callDailyInsightsWarnings,
  callPredictionMonteCarloSimulation,
  callForAppVersion,
};
